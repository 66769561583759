$_font-size-base: 16px;
$_line-height-base: 1.5;
$_line-height-computed: $_font-size-base * $_line-height-base;

@import 'tools/functions';
@import 'settings/spacing';

// Merge only the settings needed for printing.
$settings: map-collect($settings-spacing);

@media print {
    @page {
        margin: 0;
        size: landscape;
    }

    body {
        width: 297mm;
        height: 202mm;
    }

    .no-print,
    .no-print * {
        display: none;
    }

    .actions-between {
        margin-top: setting(spacing, element-narrow);
    }
}
